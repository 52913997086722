// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-p-5-1-js": () => import("./../../../src/pages/p5_1.js" /* webpackChunkName: "component---src-pages-p-5-1-js" */),
  "component---src-pages-p-5-10-js": () => import("./../../../src/pages/p5_10.js" /* webpackChunkName: "component---src-pages-p-5-10-js" */),
  "component---src-pages-p-5-11-js": () => import("./../../../src/pages/p5_11.js" /* webpackChunkName: "component---src-pages-p-5-11-js" */),
  "component---src-pages-p-5-12-js": () => import("./../../../src/pages/p5_12.js" /* webpackChunkName: "component---src-pages-p-5-12-js" */),
  "component---src-pages-p-5-2-js": () => import("./../../../src/pages/p5_2.js" /* webpackChunkName: "component---src-pages-p-5-2-js" */),
  "component---src-pages-p-5-3-js": () => import("./../../../src/pages/p5_3.js" /* webpackChunkName: "component---src-pages-p-5-3-js" */),
  "component---src-pages-p-5-4-js": () => import("./../../../src/pages/p5_4.js" /* webpackChunkName: "component---src-pages-p-5-4-js" */),
  "component---src-pages-p-5-5-js": () => import("./../../../src/pages/p5_5.js" /* webpackChunkName: "component---src-pages-p-5-5-js" */),
  "component---src-pages-p-5-6-js": () => import("./../../../src/pages/p5_6.js" /* webpackChunkName: "component---src-pages-p-5-6-js" */),
  "component---src-pages-p-5-7-js": () => import("./../../../src/pages/p5_7.js" /* webpackChunkName: "component---src-pages-p-5-7-js" */),
  "component---src-pages-p-5-8-js": () => import("./../../../src/pages/p5_8.js" /* webpackChunkName: "component---src-pages-p-5-8-js" */),
  "component---src-pages-p-5-9-js": () => import("./../../../src/pages/p5_9.js" /* webpackChunkName: "component---src-pages-p-5-9-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

